<template>
  <footer class="footer" role="contentinfo">
    <div class="inner">
      <div class="footer_nav">
        <button @click="fnPage(0)" style="color: #DBDBDB;">공지사항</button>
        <button @click="fnPage(1)" style="color: #DBDBDB;">FAQ</button>
        <button
          @click="fnPage(2)"
          style="color: #DBDBDB;"
          v-if="!$Util.isEmpty(userData)"
        >
          1:1 문의
        </button>
        <button class="mo" style="color: #DBDBDB;" @click="fnModalServiceInfo">
          서비스 안내
        </button>
      </div>
      <h1 class="logo">
        <a href="#"
          ><img src="@/assets/static/images/logo_footer.svg" alt="위즈니"
        /></a>
      </h1>
      <div class="footer_info">
        <dl>
          <dt>㈜ 유니윌 위즈페이</dt>
          <dd>대표이사 이용재</dd>
        </dl>
        <dl>
          <dt>주소</dt>
          <dd>서울시 강남구 테헤란로 124</dd>
        </dl>
        <dl>
          <dt>사업자 등록번호</dt>
          <dd>220-85-36623</dd>
        </dl>
        <dl>
          <dt>통신판매업 신고</dt>
          <dd>서울강남-02345호</dd>
        </dl>
        <dl>
          <dt>고객지원</dt>
          <dd>070-4366-6350</dd>
        </dl>
        <dl>
          <dt>영업시간</dt>
          <dd>09:00 ~ 17:00</dd>
          <dd>토, 일 및 공휴일 휴무</dd>
        </dl>
      </div>
      <div class="footer_isms">
        <div>
          (주) 유니윌지점 위즈페이 ISMS<br />
          인증범위 : 통합전자결제서비스<br />
          유효기간 : 2021년 3월 15일 ~ 2022년 3월 14일
        </div>
        <img src="@/assets/static/images/img_isms.png" alt="ISMS 인증마크" />
      </div>
    </div>
  </footer>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'footer-bar-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
