import {
  reactive,
  toRefs,
  computed,
  getCurrentInstance,
  ref,
  onMounted,
  onUnmounted
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const windowWidth = ref(window.innerWidth)
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    onCategoryItem: computed(() => getters['nav/getOnCategoryItem']),
    mainList: {},
    bestCommentList: [],
    rowType: 1,
    isAutoExtensionPopup: computed(
      () => getters['user/getIsAutoExtensionPopup']
    ),
    showMarketList: computed(() => {
      if (!proxy.$Util.isEmpty(state.mainList)) {
        if (state.winWidth > 1024) {
          return [...state.mainList.latestMarketList]
        } else {
          return state.mainList.latestMarketList.slice(0, 2)
        }
      } else {
        return []
      }
    }),
    winWidth: computed(() => windowWidth.value),
    activeIndex: 0
  })

  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const fnYoutube = async item => {
    const payload = {
      component: proxy.$modalAlertNames.YOUTUBE,
      data: { code: item },
      callback: async () => {}
    }
    await fnModalAlert(payload)
  }

  const fnMainList = async () => {
    const res = await proxy.$MainSvc.postSubscribeShare()
    state.mainList = res
    if (!proxy.$Util.isEmpty(res.myPageBadge)) {
      await dispatch('myPage/updateMyPageBadge', {
        myPageBadge: Number(res.myPageBadge)
      })
    }
    if (!proxy.$Util.isEmpty(res.penaltyDt)) {
      state.mainList.penaltyNotice = true
    }
  }

  const checkRequestExtension = async () => {
    if (state.isAutoExtensionPopup !== 'false') {
      await dispatch('user/updateIsAutoExtensionPopup', {
        isAutoExtensionPopup: false
      })
      if (
        confirm(
          `파티 종료 예정 & 변경 사항이 있습니다.\n마이페이지에서 확인 하시겠습니까?`
        )
      ) {
        await router.push('/my-page/home')
      }
    }
  }

  const checkRequestExtensionPopup = async (index = 0) => {
    if (!proxy.$Util.isEmpty(state.mainList.reqExtensionPartyList)) {
      const item = proxy.$_.cloneDeep(
        state.mainList.reqExtensionPartyList[index]
      )
      if (!proxy.$Util.isEmpty(item)) {
        const payload = {
          component: proxy.$modalAlertNames.PARTY_EXTENSION,
          data: item,
          callback: async approve => {
            if (approve === 1) {
              await router.push({
                path: '/party/pay',
                query: { partyNo: item.partyNo, partyAgree: 'Y' }
              })
            } else {
              // 연장 결제 팝업 off
              if (index < state.mainList.reqExtensionPartyList.length) {
                setTimeout(async () => {
                  await checkRequestExtensionPopup(++index)
                }, 1)
              }
            }
            // await fnRequestExtension(reqExtensionPartyList[index], index, 1)
          }
        }
        await fnModalAlert(payload)
      }
    }
  }

  const onSlideChange = event => {
    state.activeIndex = event.activeIndex
  }

  const fnToggleView = (type = 1) => {
    state.rowType = type
  }
  const fnNotice = async notice => {
    await router.push({
      path: '/cs/notice/view',
      query: { noticeId: notice.noticeId }
    })
  }
  const fnPage = async (type = 1) => {
    const categoryMap = proxy.$_.keyBy(
      getters['nav/getCategoryList'],
      'categoryID'
    )
    if (type === 1) {
      await dispatch('nav/updateOnDepth1Item', {
        onDepth1Item: categoryMap[1000]
      })
      await dispatch('nav/updateOnDepth2Item', {
        onDepth2Item: {
          categoryID: '',
          categoryName: 'ALL',
          categoryOrder: '-1',
          depth: 2,
          upperCategoryID: '0000',
          url: ''
        }
      })
      await router.push({ path: '/party/list' })
    } else if (type === 2) {
      const categoryMap = proxy.$_.keyBy(
        getters['nav/getCategoryList'],
        'categoryID'
      )
      await dispatch('nav/updateOnDepth1Item', {
        onDepth1Item: categoryMap[2000]
      })
      await router.push({ path: '/market/list' })
      // await router.push({ path: '/community/party-hope' })
    }
  }
  const getWizzpediaClassName = val => {
    const score = Number(val) * 10
    if (score < 1) {
      return ''
    }
    return `n${score}`
  }
  const fnUpdateCommentRecommend = async item => {
    if (proxy.$Util.isEmpty(state.userData)) {
      alert('로그인이 필요합니다.')
      item.recommendMine = false
      return false
    }
    const params = {}
    params.seq = item.seq
    const res = await proxy.$WizzpediaSvc.postWizzpediaUpdateCommentRecommend(
      params
    )
    if (res.resultCode !== '0000') {
      alert(res.resultMsg)
    } else {
      if (item.recommendMine) item.recommendCount--
      else item.recommendCount++
      item.recommendMine = !item.recommendMine
    }
  }
  const fnWizzpediaView = async item => {
    await router.push({
      path: '/community/wizzpedia/view',
      query: {
        id: item.id,
        category: item.category
      }
    })
  }
  /** init **/
  const init = async () => {
    await dispatch('nav/updateOnDepth1Item', { onDepth1Item: {} })
    await fnMainList()
    if (!proxy.$Util.isEmpty(state.userData)) {
      await checkRequestExtension()
      await checkRequestExtensionPopup()
    }
    if (route.hash === '#done') {
      const payload = {
        component: proxy.$modalAlertNames.REGIST_DONE,
        data: {},
        callback: async () => {}
      }
      await fnModalAlert(payload)
    } else if (!proxy.$Util.isEmpty(route.query.kind)) {
      const kind = route.query.kind
      const value = route.query.value
      const value2 = route.query.value2
      const value3 = route.query.value3
      let path = ''
      const query = {}
      if (Number(kind) === 0) {
        // 위즈톡 채팅
        query.partyNo = value
        query.chatroomId = value2
        path = '/wizz-talk'
      } else if (Number(kind) === 1) {
        if (proxy.$Util.isEmpty(value)) {
          path = '/my-page/joined-part'
        } else {
          // 참여한 파티 상세
          query.partyNo = value
          path = '/my-page/joined-party/view'
        }
      } else if (Number(kind) === 2) {
        if (proxy.$Util.isEmpty(value)) {
          path = '/my-page/created-party'
        } else {
          // 생성한 파티 상세
          query.partyNo = value
          path = '/my-page/created-party/view'
        }
      } else if (Number(kind) === 3) {
        // 공지사항/이벤트
        path = '/cs/notice'
      } else if (Number(kind) === 4) {
        // 마이페이지 > 페널티게시판
        path = '/my-page/penalty'
      } else if (Number(kind) === 5) {
        // 메인페이지
        query.isRoot = 1
        path = '/main'
      } else if (Number(kind) === 6) {
        // 마이페이지 > 파티알림
        path = '/my-page/party-noti'
      } else if (Number(kind) === 7) {
        // 마이페이지
        path = '/my-page/home'
      } else if (Number(kind) === 8) {
        if (proxy.$Util.isEmpty(value)) {
          path = '/my-page/market'
          query.index = 0
        } else {
          // 구매한 마켓 상세
          query.marketNo = value
          query.memberSeq = value2
          path = '/my-page/market/buyer-view'
        }
      } else if (Number(kind) === 9) {
        if (proxy.$Util.isEmpty(value)) {
          path = '/my-page/market'
          query.index = 1
        } else {
          // 판매한 마켓 상세
          query.marketNo = value
          path = '/my-page/market/view'
        }
      } else if (Number(kind) === 10) {
        // 위즈톡 채팅
        query.marketNo = value
        query.chatroomId = value2
        query.memberSeq = value3
        path = '/market/wizz-talk'
      }
      if (!proxy.$Util.isEmpty(path)) {
        const signKinds = [0, 1, 2, 4, 6, 7, 8, 9, 10]
        if (
          signKinds.indexOf(Number(kind)) > -1 &&
          proxy.$Util.isEmpty(state.userData)
        ) {
          alert('로그인이 필요합니다.')
          await router.replace({ path: '/main', query: { isRoot: 1 } })
          await router.push({ path: '/login' })
        } else {
          await router.replace({ path: '/main', query: { isRoot: 1 } })
          await router.push({ path: path, query: query })
        }
      }
    }
    // const payload = {
    //   component: proxy.$modalAlertNames.BASE,
    //   data: {},
    //   layout: { title: '테스트', contents: '테스트 콘텐츠' },
    //   callback: async () => {
    //     alert()
    //   },
    //   callBackText: '확인'
    // }
    // await fnModalAlert(payload)
  }
  init()
  return {
    ...toRefs(state),
    fnMainList,
    fnToggleView,
    fnNotice,
    fnPage,
    getWizzpediaClassName,
    fnUpdateCommentRecommend,
    fnWizzpediaView,
    fnYoutube,
    onSlideChange
  }
}
