import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { fnModal } from '@/components/common/modal'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters } = useStore()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData'])
  })
  const fnPage = async (tabIndex = 1) => {
    await router.push({ path: proxy.$ConstCode.CS_TABS[tabIndex].path })
  }
  const fnModalServiceInfo = async () => {
    const payload = {
      component: proxy.$modalNames.SERVICE_INFO,
      data: {},
      callback: () => {}
    }
    await fnModal(payload)
  }
  return { ...toRefs(state), fnPage, fnModalServiceInfo }
}
