<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner bg">
        <div class="page_con">
          <!--      최근 등록 파티를 상품으로 변경 및 공지 추가 20210809    -->
          <div class="notice_main" v-if="!$Util.isEmpty(mainList.noticeList)">
            <swiper :slides-per-view="1">
              <swiper-slide
                v-for="(item, index) in mainList.noticeList"
                :key="index"
              >
                <div class="notice_main__inner" @click="fnNotice(item)">
                  <div class="ttl">
                    <span>공지</span>
                  </div>
                  <ul class="list">
                    <li>
                      {{ $dayjs(item.regDt).format('YY.MM.DD') }}
                      {{ item.title }}
                    </li>
                    <!--말줄임처리-->
                  </ul>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <!--          <div class="notice_main" v-if="!$Util.isEmpty(mainList.noticeList)">
            <div class="notice_main__inner" @click="fnNotice(mainList.notice)">
              <div class="ttl">
                <span>공지</span>
              </div>
              <ul class="list">
                <li>21.6.21 {{ mainList.noticeList[0].title }}</li>
                &lt;!&ndash;말줄임처리&ndash;&gt;
              </ul>
            </div>
          </div>-->
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="list_top_ttl" @click="fnPage(2)">
                  최근 마켓 상품
                </button>
              </div>
              <div class="right">
                <button
                  class="li_btn col"
                  :class="{ on: rowType === 1 }"
                  @click="fnToggleView(1)"
                ></button
                ><!--'on'-->
                <button
                  class="li_btn row"
                  :class="{ on: rowType === 2 }"
                  @click="fnToggleView(2)"
                ></button>
              </div>
            </div>
            <market-list
              :items="showMarketList"
              v-if="!$Util.isEmpty(showMarketList)"
            />
            <!--      최근 등록 파티를 상품으로 변경 20210809    -->
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="list_top_ttl" @click="fnPage(1)">
                  최근 구독 상품
                </button>
              </div>
              <!--              <div class="right">-->
              <!--                <button-->
              <!--                  class="li_btn col"-->
              <!--                  :class="{ on: rowType === 1 }"-->
              <!--                  @click="fnToggleView(1)"-->
              <!--                ></button-->
              <!--                >&lt;!&ndash;'on'&ndash;&gt;-->
              <!--                <button-->
              <!--                  class="li_btn row"-->
              <!--                  :class="{ on: rowType === 2 }"-->
              <!--                  @click="fnToggleView(2)"-->
              <!--                ></button>-->
              <!--              </div>-->
            </div>
            <party-list
              :row-type="rowType"
              :items="mainList.latestPartyList"
              v-if="!$Util.isEmpty(mainList.latestPartyList)"
            />
            <!--      최근 등록 파티를 상품으로 변경 20210809    -->
          </div>
          <div
            class="party_list_wrap"
            v-if="!$Util.isEmpty(mainList.wizzpediaCommentList)"
          >
            <div class="list_top">
              <div class="left">
                <button class="list_top_ttl">
                  위즈피디아 평점
                </button>
              </div>
              <div class="right">
                <div
                  class="wizzpedia_pager"
                  v-if="!$Util.isEmpty(mainList.wizzpediaCommentList)"
                >
                  <ul>
                    <li
                      :class="{ on: index === activeIndex }"
                      v-for="(item, index) in mainList.wizzpediaCommentList"
                      :key="index"
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row_type">
              <!--'가로형: row_type'-->
              <!--:autoplay="{ delay: 3000 }"-->
              <!--:navigation="true" :pagination="{ clickable: true }"-->
              <swiper
                :slides-per-view="1"
                autoHeight
                @slideChange="onSlideChange"
              >
                <swiper-slide
                  tag="ul"
                  class="wzp_list_ul"
                  style="padding: 0"
                  v-for="(item, index) in mainList.wizzpediaCommentList"
                  :key="index"
                >
                  <li
                    class="wzp_list_li click_bg_dt"
                    style="flex-direction: column"
                    @click="fnWizzpediaView(item)"
                  >
                    <div class="flex_area">
                      <div class="box txt">
                        <div class="party_ttl">
                          <div class="party_tag">
                            <div class="left">
                              <div
                                class="pt_tag logo"
                                :style="{
                                  '--pt-bg-color': $ConstCode.getCategoryByText(
                                    item.category
                                  ).color
                                }"
                              >
                                {{
                                  $ConstCode.getCategoryByText(item.category)
                                    .name
                                }}
                              </div>
                            </div>
                          </div>
                          <span style="font-weight: 500;">{{
                            item.titleKr
                          }}</span>
                        </div>
                      </div>
                      <div class="box img">
                        <div class="thumb">
                          <div class="inner">
                            <img :src="item.thumbnail" :alt="item.titleKr" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="comment_list m_0">
                      <ul class="comment_list_ul p_0">
                        <li class="comment_list_li p_0">
                          <div class="top">
                            <div class="main_star_heart">
                              <div class="rank">
                                <div
                                  class="stars"
                                  :class="
                                    getWizzpediaClassName(item.starRating)
                                  "
                                >
                                  <!--'0.5점: n05', '1.0점: n10', ~ '5.0점: n50'-->
                                  <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                  </ul>
                                </div>
                                <div class="rank_nb">
                                  <span>{{ item.starRating }}</span>
                                </div>
                              </div>
                              <div class="heart_area">
                                <div class="heart_nb">
                                  {{ item.recommendCount }}
                                </div>
                                <label class="checkbox">
                                  <input
                                    type="checkbox"
                                    :checked="item.recommendMine"
                                  />
                                  <span
                                    class="label"
                                    @click.prevent.stop="
                                      fnUpdateCommentRecommend(item)
                                    "
                                  >
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="comment_list_ttl">
                              <div class="left">
                                <div class="name">{{ item.name }}</div>
                                <div class="color" v-show="item.critic">
                                  Critic
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="btm ellipsis">
                            <span
                              ><!--붙여쓰기-->{{ item.comment
                              }}<!--붙여쓰기--></span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <div
            class="party_list_wrap"
            v-if="!$Util.isEmpty(mainList.deadlineList)"
          >
            <div class="list_top">
              <div class="left">
                <div class="list_top_ttl">마감 예정</div>
              </div>
              <div class="right"></div>
            </div>
            <party-list
              :row-type="rowType"
              :items="mainList.deadlineList"
              :is-nick="false"
              :is-date="false"
              v-if="!$Util.isEmpty(mainList.deadlineList)"
            />
          </div>
          <!--
              ★★★웹에서는 모든 리스트 게시물 4개씩 보여져야 합니다.★★★
              ★★★웹 = 100vw가 1025px 이상일 때★★★
          -->
          <!--          <div
            class="party_list_wrap"
            v-if="!$Util.isEmpty(mainList.shortPartyList)"
          >
            <div class="list_top">
              <div class="left">
                <div class="list_top_ttl">초 단기 파티 모집</div>
              </div>
              <div class="right"></div>
            </div>
            <party-list
              :row-type="rowType"
              :items="mainList.shortPartyList"
              v-if="!$Util.isEmpty(mainList.shortPartyList)"
            />
          </div>-->
          <!-- 20210809 해당하는 이미지 사이즈에 배너리스트 불러와서 뿌리기만함 -->
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="list_top_ttl">
                  지금 뜨는 영상
                </button>
              </div>
              <div class="right"></div>
            </div>
            <div class="party_list row_type">
              <!--'가로형: row_type'-->
              <!-- :slides-per-view="$Util.isMobile() ? 1 : 2" -->
              <swiper
                tag="div"
                class=""
                :autoplay="{ delay: 3000 }"
                :slides-per-view="$Util.isMobile() ? 1 : 2"
                autoHeight
                @slideChange="() => {}"
                :pagination="{ clickable: true }"
                :space-between="16"
              >
                <swiper-slide
                  tag="div"
                  class="row_type_thumb"
                  v-for="(item, index) in mainList.youtubeKeyList"
                  :key="index"
                  @click="fnYoutube(item)"
                  style="position: relative; display: flex;"
                >
                  <img
                    :src="`https://img.youtube.com/vi/${item}/mqdefault.jpg`"
                  />
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <footer-bar />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import MarketList from '@/components/views/market/list/index.vue'
import PartyList from '@/components/views/party/list/index.vue'
import FooterBar from '@/components/common/layouts/footer-bar/index.vue'

export default {
  name: 'main-index',
  components: { MarketList, PartyList, FooterBar },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped lang="scss">
.swiper-pagination-bullet {
  background: #dbdbdb !important;
}
</style>
